import * as React from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./../Assets/styles/font.css";
import { Container, Button, Row, Col } from "react-bootstrap";
import Buttons from "../components/Buttons/button";
import Layout from "../components/layout"
import Footer from "../components/Footer/Footer.js"
import Seo from "../components/seo"
import { graphql } from 'gatsby';
import "../Assets/styles/index.scss";
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import Pulse from 'react-reveal/Pulse';
//images
import imageBG from "../Assets/images/Home/home-bg.png";
// import trueSVG from "../Assets/images/Home/true.svg";
// import calendarSVG from "../Assets/images/Home/calendar.svg";
// import personsSVG from "../Assets/images/Home/persons.svg";
// import watchSVG from "../Assets/images/Home/watch.svg";
import handshakeImg from "../Assets/images/Home/handshake.png";
import upworkImg from "../Assets/images/Home/upwork.png";
import dotImg from "../Assets/images/Home/dotImg.png";
// import userTestomials from "../Assets/images/Home/testimonials/user.png";
import rightDotTestomials from "../Assets/images/Home/testimonials/right-dot.png";
import mobileAppDevelopmentImg from "../Assets/images/Home/mobile-app-development.png";
import androidSVG from "../Assets/images/Home/mobileDevelopment/android.svg";
import iosSVG from "../Assets/images/Home/mobileDevelopment/ios.svg";
import reactnativeSVG from "../Assets/images/Home/mobileDevelopment/react-native.svg";
// import trofiAppImg from "../Assets/images/Home/app/trofiApp.png";
// import audioBookImg from "../Assets/images/Home/app/audio-books.png";
// import coralImg from "../Assets/images/Home/app/coral.png";
// import innerRoomImg from "../Assets/images/Home/app/innerRoom.png";
import mobile from "../Assets/images/Home/mobile.png";
import database from "../Assets/images/Home/database.png";
import backgroundImg from "../Assets/images/Home/backgroundImg.png";

//strapi URL
import { imagePathURL } from "../utils/JSONData";
import { useState } from "react";
import Scrolltop from "../components/Scrolltotop/scrolltop";
// const projectWorks = [
//   {
//     image: trueSVG,
//     number: '123+',
//     text: 'Project Compleate'
//   },
//   {
//     image: calendarSVG,
//     number: '1+',
//     text: 'Years of experience'
//   },
//   {
//     image: personsSVG,
//     number: '10+',
//     text: 'Satisfied Client'
//   },
//   {
//     image: watchSVG,
//     number: '1k+',
//     text: 'Freelance Hours'
//   }
// ];

const sliderSettings = {
  dots: true,
  speed: 500,
  arrows: false,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      },
    },

    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      },
    },
  ],
};
const devsliderSettings = {
  dots: true,
  speed: 500,
  arrows: false,
  infinite: true,
  initialSlide: 0,
  slidesToShow: 4,
  slidesToScroll: 1,
  // autoplay: true, 
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      },
    },

    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      },
    },
    {
      breakpoint: 570,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      },
    },
  ],
  // autoplay: true,
};

// const testimonials = [
//   {
//     name: 'jhons Patel',
//     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
//   },
//   {
//     name: 'jhons Patel',
//     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
//   },
//   {
//     name: 'jhons Patel',
//     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
//   },
//   {
//     name: 'jhons Patel',
//     description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
//   }
// ];

// const ourWorkItems = [
//   {
//     title: "Trofi: Earn Interest on Crypto App",
//     sort_des: "Mobile Application Development",
//     description: "Earn interest with all-in-one wealth management app for your crypto assets. Swap or buy crypto, and make passive income on crypto investment.Are you looking for a multi-purpose crypto wealth management app to help you gain better control over your crypto assets? Would you like to make crypto investment and make passive income from interest on your investments? If that is the case, this licensed and regulated crypto platform is the perfect place for you to get started. Buy crypto or swap crypto assets, get crypto loan and earn crypto currency from your investments with one of the best crypto apps available online. This crypto currency app lets you deposit your currency for short term or long term, so you can get better interests and earn crypto income easily.",
//     image: trofiAppImg,
//     appLink: "",
//     playLink: ""
//   },
//   {
//     title: "Coral: Relationship Coach App & Web",
//     sort_des: "Mobile Application Development And Web Development",
//     description: "Real couples need help sustaining real intimacy. Join the hundreds of thousands of people using Coral to maintain fun, healthy and deeply connected relationships.“My wife and I have really learned and grown… {we’re) understanding each other’s needs better, as well as better communicating our needs and desires for each other. – James, 38",
//     image: coralImg,
//     appLink: "",
//     playLink: ""
//   },
//   {
//     title: "Inner Room App",
//     sort_des: "Mobile Application Development",
//     description: "Inner Room is a creative, free prayer list app from 24-7 Prayer, that helps you turn your biggest distraction into a tool for prayer.Jesus said, ‘But you, when you pray, go into your inner room, close your door and pray to your Father...’ Matthew 6:6 (NASB)Turn your phone into an ‘Inner Room’ and pray any time, any where. Whether you’re at home, college, work or on the move, remember and act on the things you want to pray for.",
//     image: innerRoomImg,
//     appLink: "",
//     playLink: ""
//   },
//   {
//     title: "Almost Everything Audiobooks App",
//     sort_des: "Mobile Application Development",
//     description: "Using almost everything app, you can listen Tamil book summaries in audio format.",
//     image: audioBookImg,
//     appLink: "",
//     playLink: ""
//   },
// ];

const IndexPage = ({ data }) => {
  const [activeTitle, setActiveTitle] = useState('IOS');

  const handleTitleClick = (title) => {
    setActiveTitle(title);
  };

  const titleStyle = {
    borderBottom: '2px solid #00467f',
    // boxShadow: 'rgb(6 17 27 / 20%) 0px 8px 24px'
    boxShadow: '1px 2px 9px #16467f',
    borderRadius:'9px',
  };


  return (
    <Layout>
      <Seo title="Home" />
      <div className="home">
        <div className="home__bg-image">
          <div className="mapimage">
          <img className="mainimage" src={imageBG} alt="first-background" />
          </div>

          <div className="home__main-text">
            <Flip left>
              <h1 className="home__main-title">{data.allHomeTitle2.nodes[0].data.attributes.title}</h1>
            </Flip>
            {/* <Fade left> */}
            <div className="home__description-text-1">{data.allHomeTitle2.nodes[0].data.attributes.description}</div>
            <div className="home__description-text-2">{data.allHomeTitle2.nodes[0].data.attributes.description2}</div>
            {/* </Fade> */}
            <div>
              <Button>
                <a href=" https://calendly.com/hiteshkachhadiya" className="schedule_btn" target="blank"> Schedule a meeting</a>
              </Button>
            </div>
          </div>

          <div className="home__work-section">
            <Container>
              <Row style={{justifyContent:'center'}}>
                {data.allProjectWork2.nodes[0].data.map((projectWork, index) => {
                  return (
                    <Col xs={12} md={3} sm={6} className="workImg-text py-2" key={index}>
                      <img src={`${imagePathURL + projectWork.attributes.image.data.attributes.url}`} alt="project-work" />
                      <div>
                        <div className="number">{projectWork.attributes.title}</div>
                        <div className="name">{projectWork.attributes.description}</div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Container>
          </div>
        </div>

        <img src={backgroundImg} alt="background" className="home__first-background" />
        <div className="our-services">
          <Container>
            <Row>
              <Col xs={12} md={5} className="our-services__left-title-container">
                <Fade left>
                  <div className="our-services__left-title">
                    Find the <span>right offers</span> for you.
                  </div>
                </Fade>
              </Col>
              <Col xs={12} md={7} className="our-services__right-section">
                <div className="title-services">
                  <div className="title-services__container">
                    <div>
                      Our Services
                    </div>
                    <div className="our-services__small-description">
                      We have Competency in
                    </div>
                  </div>
                </div>
                <img src={handshakeImg} alt="hand-shake" />
                {/* <StaticImage
                src="../Assets/images/Home/handshake.png"
                loading="eager"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
              /> */}
              </Col>
            </Row>
          </Container>
        </div>

        <div className="development">
          <Row>
            <Slider className="el-slider el-slider-plr--15" {...devsliderSettings}>
              {data.allService.nodes[0].data.map((service, index) => {
                return (
                  <Col className="mr-20 boxs" key={index}>
                    <div className={`${index % 2 ? 'database' : null} development-card`}>
                      <img src={index % 2 ? database : mobile} alt="mobile"></img>
                      <h2>{service.attributes.title}</h2>
                      <p>{service.attributes.description}</p>
                    </div>
                  </Col>
                )
              })}
            </Slider>
          </Row>
        </div>

        <div className="mobile-development">
          <Container>
            <Row>
              <Col xs={12} md={5} className="mobile-development__left-container">
                <Fade left>
                  <img src={mobileAppDevelopmentImg} className="mobile-development__left-img" alt="developing" />
                </Fade>
              </Col>

              <Col xs={12} md={7} className="mobile-development__right-container">
                <Fade right>
                  <div className="mobile-development__title-container">
                    <div className="mobile-development__title">
                      <h3>
                        Mobile App Development
                      </h3>
                    </div>
                  </div>
                  <div className="mobile-development__text-container">
                    <div>
                      <p>
                        {activeTitle === 'IOS' && (
                          // <div className="mobile-development__text-container">
                          <p>We craft sleek and user-friendly apps that seamlessly integrate with Apple's ecosystem. Whether you need an app for iPhones or iPads, we ensure a smooth user experience that aligns with Apple's high standards.</p>
                          // </div>
                        )}
                        {activeTitle === 'Android' && (
                          // <div className="mobile-development__text-container">
                          <p>We harness the power of diversity. Our apps are optimized for a wide range of Android devices, ensuring accessibility to a broad user base. Our focus is not just on functionality, but also on a visually appealing and intuitive design.</p>
                          // </div>
                        )}
                        {activeTitle === 'React-Native' && (
                          // <div className="mobile-development__text-container">
                          <p>The cross-platform solution, enables us to develop apps for both iOS and Android simultaneously, saving time and resources. Our apps built on React Native are known for their high performance and consistent user experience.</p>
                          // </div>
                        )}
                      </p>
                      {/* <div className="mobile-development__mobile-title">
                        <img src={iosSVG} alt="ios" />
                        <p>IOS</p>
                      </div>
                      <div className="mobile-development__mobile-title">

                        <span className="underline-andriod" >
                          <div className="mobile-development__mobile-title-img">

                            <img src={androidSVG} alt="android" />
                          </div>
                          <p>Android</p>
                        </span>
                      </div>
                      <div className="mobile-development__mobile-title">
                        <img src={reactnativeSVG} alt="react-native" />
                        <p>React-Native</p>
                      </div>
                    </div> */}
                      {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        <div
                          className={`mobile-development__mobile-title ${activeTitle === 'IOS' ? 'active' : ''}`}
                          onClick={() => handleTitleClick('IOS')}
                        >
                          <img src={iosSVG} alt="ios" />
                        </div>
                        <div
                          className={`mobile-development__mobile-title ${activeTitle === 'Android' ? 'active' : ''}`}
                          onClick={() => handleTitleClick('Android')}
                        >
                          <div className="mobile-development__mobile-title-img">
                            <img src={android3} alt="android" />
                          </div>
                        </div>
                        <div
                          className={`mobile-development__mobile-title ${activeTitle === 'React-Native' ? 'active' : ''}`}
                          onClick={() => handleTitleClick('React-Native')}
                        >
                          <img src={reactnativeSVG} alt="react-native" />
                        </div>
                      </div> */}
                      {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        <div className="mobile-development__mobile-title" style={activeTitle === 'IOS' ? titleStyle : {}} onClick={() => handleTitleClick('IOS')}>
                        <div className="mobile-development__mobile-title-img">
                          <img className="mobileimage" src={iosSVG} alt="ios" />
                        </div>
                          <div className="mobile-development__dot" style={activeTitle === 'IOS' ? { display: 'block' } : {}}></div>
                          <p>IOS</p>
                        </div>
                        <div className="mobile-development__mobile-title" style={activeTitle === 'Android' ? titleStyle : {}} onClick={() => handleTitleClick('Android')}>
                          <div className="mobile-development__mobile-title-img">
                            <img className="mobileimage" src={Androidsvg} alt="android" />
                          </div>
                           <p>Android</p> 
                        </div>
                        <div className="mobile-development__mobile-title" style={activeTitle === 'React-Native' ? titleStyle : {}} onClick={() => handleTitleClick('React-Native')}>
                        <div className="mobile-development__mobile-title-img">
                          <img className="mobileimage" src={reactnativeSVG} alt="react-native" />
                          </div>
                           <p>React-Native</p> 
                        </div>
                      </div> */}
                      <div className="moblie-devlopment" style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                        <div className="moblietitle" style={activeTitle === 'IOS' ? titleStyle : {}} onClick={() => handleTitleClick('IOS')}>
                          <img className="moblieimg" src={iosSVG} alt="ios" />
                        </div>
                        <div className="moblietitle" style={activeTitle === 'Android' ? titleStyle : {}} onClick={() => handleTitleClick('Android')}>
                          <img className="moblieimg" src={androidSVG} alt="ios" />
                        </div>
                        <div className="moblietitle" style={activeTitle === 'React-Native' ? titleStyle : {}} onClick={() => handleTitleClick('React-Native')}>
                          <img className="moblieimg" src={reactnativeSVG} alt="ios" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="upwork-section">
          <Container>
            <Row>
              <Col xs={12} md={6} className="text-center m-auto">
                <div>
                  <Fade left>
                    <div className="upwork-section__title">
                      Top Web and Mobile App Development Company
                    </div>
                    <div className="upwork-section__big-title">
                      <span>Awards</span> & Recognitions
                    </div>
                  </Fade>
                </div>
              </Col>
              <Col xs={12} md={6} className="text-center">
                <Pulse>
                  <img src={upworkImg} alt="upwork" />
                </Pulse>
                <img src={dotImg} alt="dot" className="upwork-section__dot-image" />
                {/* <StaticImage
                src="../Assets/images/Home/handshake.png"
                loading="eager"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt=""
              /> */}
              </Col>
            </Row>
          </Container>
        </div>

        <div className="our-work">
          <div className="our-work__title text-center">
            <span style={{ display: "inline-block" }}>
              Our Work
              <div style={{ display: "flex", justifyContent: "space-evenly" }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "5px", width: "35%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "5px", width: "6%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "5px", width: "35%" }}> </div>
              </div>
            </span>
          </div>

          <div className="our-work__description text-center">
            Madhav technologies has a great team of enthusiasts who are madly in love with the software, and henceforth developed plenty of stunning apps
          </div>

          <div>
            <Container>
              {
                data.allOurWorks.nodes[0].data.map((ourWorkItem, index) => {
                  return (
                    <>
                      <Row className="my-5" key={index}>
                        <Col xs={12} md={6} className={`${index % 2 === 0 ? "order-1" : "order-2"} our-work__item-section`}>
                          <Fade left>
                            <div className="our-work__title">
                              {ourWorkItem.attributes.title}
                            </div>
                            <div className="our-work__description-1">
                              {ourWorkItem.attributes.titleSmall}
                            </div>
                            <p className="our-work__description-2">
                              {ourWorkItem.attributes.description}
                            </p>
                            <div className="our-work__available-app">
                              Available on app
                            </div>
                            <Buttons ourWorkItem={ourWorkItem.attributes} />
                          </Fade>
                        </Col>
                        <Col xs={12} md={6} className={`${index % 2 === 0 ? "order-2" : "order-1"} p-0`}>
                          <Fade right>
                            <img src={imagePathURL + ourWorkItem.attributes.image.data.attributes.url} alt="trofi" className="our-work__item-img" />
                            {/* <img src={"/coral_db11d8b43e-removebg-preview.png"} alt="trofi" className="our-work__item-img" /> */}
                          </Fade>
                        </Col>
                      </Row>
                      {index === 0 &&
                        <Fade right>

                          <div className="arrow-container1">
                            <div className="arrow">
                            </div>
                          </div>
                        </Fade>
                      }
                      {index === 2 &&
                        <Fade right>
                          <div className="arrow-container2">
                            <div className="arrow">
                            </div>
                          </div>
                        </Fade>
                      }
                    </>
                  )
                })
              }
            </Container>
          </div>
        </div>


        <div className="client-say">
          <div className="our-work__title text-center">
            <span style={{ display: "inline-block" }}>

              Client Say Something
              <div style={{ display: "flex", justifyContent: "center" }} >
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "15%", marginRight: "10px" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "3%" }}></div>
                <div style={{ borderBottom: "3px solid #FF9C4F", borderRadius: "10px", marginTop: "10px", width: "15%", marginLeft: "10px" }}> </div>
              </div>
            </span>
          </div>

          <div>
            <div className="testimonials-section">
              <div className="testimonials-section__success">Success</div>
              <img className="testimonials-section__image" alt="right-dot" src={rightDotTestomials} />

              <Slider className="el-slider el-slider-plr--15" {...sliderSettings}>
                {data.allTestimonials.nodes[0].data.map((testimonial, index) => {
                  return (
                    <div key={index}>
                      <img src={imagePathURL + testimonial.attributes.image.data.attributes.url} alt="testimonial" className="image-testimonial" />
                      <div>
                        <div className="client-say__container">
                          <h3>{testimonial.attributes.name}</h3>
                          <p>{testimonial.attributes.review}</p>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="home-footer">
        <Footer />
      </div>

      <Scrolltop />
    </Layout>
  )
};

export const Head = () => <Seo title="Home" />

export const query = graphql`
  {
    allService {
      nodes {
        data {
          attributes {
            title
            description
          }
        }
      }
    }

    allHomeTitle2 {
      nodes {
        data {
          attributes {
            title
            description
            description2
          }
        }
      }
    }

    allProjectWork2 {
      nodes {
        data {
          attributes {
            title
            description
            imageName
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allTestimonials {
      nodes {
        data {
          attributes {
            name
            review
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

    allOurWorks {
      nodes {
        data {
          attributes {
            apple_link
            description
            google_link
            title
            titleSmall
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }

  }
`;


export default IndexPage