import * as React from "react"
import appStore from '../../Assets/images/Butttons/appStore-2.png';
import googlePlay from '../../Assets/images/Butttons/googlePlay-2.png';
import './button.scss';

const Buttons = (props) => {

    // const handleAppStoreClick = () => {
    //     window.open(props.ourWorkItem.apple_link, '_blank');
    // };

    // const handleGooglePlayClick = () => {
    //     window.open(props.ourWorkItem.google_link, '_blank');
    // };

    return (
        <div className="buttons">
            <a href={props.ourWorkItem.apple_link} target="_blank">
            <img src={appStore} alt="app-store" className="buttons__app-store" />
            </a>
            <a href={props.ourWorkItem.google_link} target="_blank" >
            <img src={googlePlay} alt="google-play" className="buttons__google-play" />
            </a>
        </div>
    )
}

export default Buttons;